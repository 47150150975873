import React, { Fragment } from "react";
import Metadata from "../components/Metadata";
import Video from "../components/Video";

import videoUrl from "../static/video/video-preview-handbraked.mp4";

export default function videoPage() {
    return (
        <Fragment>
            <Metadata />
            <Video urlVideo={videoUrl} captions="video" />
        </Fragment>
    );
}