import React from 'react';
import videoStyles from "../styles/components/video.module.scss";

export default function Video({ onVideoEnd = () => {}, urlVideo, ...props }) {

    return (
        <div className={videoStyles.videoWrapp}>
            <video controls onEnded={onVideoEnd} {...props} playsInline controlsList="nodownload" disablePictureInPicture>
                <source src={urlVideo} type="video/mp4" />
                <track kind="captions" srcLang="en" />
            </video>
        </div>
    );
}